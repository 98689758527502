import axios from 'axios'
import { getTransitionRawChildren } from 'vue';
import VueAxios from 'vue-axios'

axios.defaults.withCredentials = true

axios.defaults.headers= {
    'Content-Type': 'application/json',
  }

export default {
    //emits:['showActionBar', 'closeSideBar'],
    //emits:['showAlert'],
    data() {
        return {

        }
    },
    computed: {
        projectName() {
            return process.env.VUE_APP_NAME;
        },
        getLogin() {
            return this.$store.getters['app/getLogin']
        },
        loggedIn() {
            var login = this.$store.getters.getLogin;
            if (login) return true;
            return false;
        },
        
    },
    components: {
        // 'confirmation-dialog': ConfirmationDialog,
        // 'waiting-dialog': WaitingDialog
        
    },
    methods: {
        hasPermission(permissionName) {
            if (this.getLogin.Role.id) {
                return this.getLogin.Role.customPermissions.includes(permissionName);
            }
            return false;
        },
        async checkLogin() {
            const res = await this.callApi('get', '/login/check');
            //console.log(res)
            if (!res.data.login) {
                
                //console.log('logged out', this.$route)
                if (!this.$route.meta.noLoginRequired) {
                    if (this.$route.path != '/login') {
                        this.$router.push('/login').catch();;
                    }

                }
            }
            else {
                if (!res.data.login.login2fa) {
                    this.$router.push('/2fa');
                }
            }
        },
        async logout() {
            var res = await this.callApi('post', '/login/logout');
            this.$router.push('/login');
        },
        async uploadFile(url, data, file) {
            try {
                let formData = new FormData();
                formData.append("file", file);

                for (var i in data) {
                    formData.append(i, data[i]);
                }
                // axios.post('/bezkoder.com/upload', formData, {
                //     headers: {
                //     "Content-Type": "multipart/form-data",
                //     }
                // });
                var res = await axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_BASE+url,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        }
                });
                
                if (res.status==419) {
                    window.location.href = '/';
                }
                
                if (res.data.error) {
                    alert(res.data.error);
                }
                
                this.$store.dispatch('app/setLogin', res.data.login)

                return res;
            }
            catch(e) {
                console.log("CallApi ERROR", e);
                if (e.response && e.response.status==401) {
                    // unauthorized
                    console.log('unauthorized'.this.$route)
                    if (this.$route.path != '/login') {
                        this.$router.push('/login').catch();
                    }
                    return {data:false};
                }
                
            }
        },
        async callApi(method, url, dataObject) {
            try {
                var res = await axios({
                    method: method,
                    url: process.env.VUE_APP_API_BASE+url,
                    data: dataObject
                });
                
                if (res.status==419) {
                    window.location.href = '/';
                }
                //console.log(res)
                this.$store.dispatch('app/setLogin', res.data.login)
                if (res.data.loginRequired && !res.data.login) {
                    //window.location.
                    if (this.$route.path != '/login') {
                        this.$router.push('/login').catch();
                    }
                    //window.location.href = '/login';
                    return res;
                }

                if (res.data.error) {
                    var variant = 'danger'
                    this.$bvToast.toast(res.data.error, {
                        title: `Error`,
                        variant,
                        solid: true,
                      })
                    // alert(res.data.error);
                    // this.$parent.$emit('showAlert', 'error', 'Fout', res.data.error);
                    // this.$emit('showAlert', 'error', 'Fout', res.data.error);
                }
                

                return res;
            }
            catch(e) {
                console.log("CallApi ERROR", e);
                if (e.response && e.response.status==401) {
                    // unauthorized

                    console.log('unauthorized'.this.$route)
                    if (this.$route.path != '/login') {
                        this.$router.push('/login').catch();
                    }
                    return {data:false};
                }
                else {
                    alert('Er is een fout opgetreden: '+e.message)
                }
                
            }
        },

    }
}