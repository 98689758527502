import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account/Account.vue'),
      meta: {
        pageTitle: 'Account',
        breadcrumb: [
          {
            text: 'Account',
            active: true,
          },
        ],
      },
    },        
    {
      path: '/urgency-levels',
      name: 'urgency-levels',
      component: () => import('@/views/urgencylevels/UrgencyLevels.vue'),
      meta: {
        pageTitle: 'Urgency levels',
        breadcrumb: [
          {
            text: 'Urgency levels',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/advices',
      name: 'advices',
      component: () => import('@/views/advices/Advices.vue'),
      meta: {
        pageTitle: 'Advices',
        breadcrumb: [
          {
            text: 'Advices',
            active: true,
          },
        ],
      },
    },       
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/views/organizations/Organizations.vue'),
      meta: {
        pageTitle: 'Organizations',
        breadcrumb: [
          {
            text: 'Organizations',
            active: true,
          },
        ],
      },
    },       
    {
      path: '/exercises',
      name: 'exercises',
      component: () => import('@/views/exercises/Exercises.vue'),
      meta: {
        pageTitle: 'Exercises',
        breadcrumb: [
          {
            text: 'Exercises',
            active: true,
          },
        ],
      },
    },
    {
      path: '/exercise/:id',
      name: 'exercise-id',
      component: () => import('@/views/exercises/Exercise.vue'),
      meta: {
        pageTitle: 'Exercise',
        breadcrumb: [
          {
            text: 'Exercise',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/role/:id',
      name: 'role-id',
      component: () => import('@/views/roles/Role.vue'),
      meta: {
        pageTitle: 'Role',
        breadcrumb: [
          {
            text: 'Role',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/exercise',
      name: 'exercise',
      component: () => import('@/views/exercises/Exercise.vue'),
      meta: {
        pageTitle: 'Exercises',
        breadcrumb: [
          {
            text: 'Exercises',
            active: true,
          },
        ],
      },
    },   
    {
      path: '/conditions',
      name: 'conditions',
      component: () => import('@/views/conditions/Conditions.vue'),
      meta: {
        pageTitle: 'Conditions',
        breadcrumb: [
          {
            text: 'Conditions',
            active: true,
          },
        ],
      },
    },  
    {
      path: '/condition/:id',
      name: 'condition-id',
      component: () => import('@/views/conditions/Condition.vue'),
      meta: {
        pageTitle: 'Condition',
        breadcrumb: [
          {
            text: 'Conditions',
            active: true,
          },
        ],
      },
    },                
    {
      path: '/triage-rules',
      name: 'triage-rules',
      component: () => import('@/views/triagerules/TriageRules.vue'),
      meta: {
        pageTitle: 'Triage rules',
        breadcrumb: [
          {
            text: 'Triage rules',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/triage-rules/:id',
      name: 'triage-rules-id',
      component: () => import('@/views/triagerules/TriageRules.vue'),
      meta: {
        pageTitle: 'Triage rules',
        breadcrumb: [
          {
            text: 'Triage rules',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/triage-rule',
      name: 'triage-rule',
      component: () => import('@/views/triagerules/TriageRule.vue'),
      meta: {
        pageTitle: 'Triage rule',
        breadcrumb: [
          {
            text: 'Triage rule',
            active: true,
          },
        ],
      },
    },       
    {
      path: '/triage-rule-versions',
      name: 'triage-rule-versions',
      component: () => import('@/views/triagerules/TriageRuleVersions.vue'),
      meta: {
        pageTitle: 'Triage rule versions',
        breadcrumb: [
          {
            text: 'Triage rule versions',
            active: true,
          },
        ],
      },
    },       
    {
      path: '/triage-rule/:id',
      name: 'triage-rule-id',
      component: () => import('@/views/triagerules/TriageRule.vue'),
      meta: {
        pageTitle: 'Triage rule',
        breadcrumb: [
          {
            text: 'Triage rule',
            active: true,
          },
        ],
      },
    },       
    {
      path: '/triage-rules-old',
      name: 'triage-rules-old',
      component: () => import('@/views/triagerules_old/TriageRules.vue'),
      meta: {
        pageTitle: 'Triage rules',
        breadcrumb: [
          {
            text: 'Triage rules',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/triage-rule-old',
      name: 'triage-rule-old',
      component: () => import('@/views/triagerules_old/TriageRule.vue'),
      meta: {
        pageTitle: 'Triage rule',
        breadcrumb: [
          {
            text: 'Triage rule',
            active: true,
          },
        ],
      },
    },       
    {
      path: '/results',
      name: 'results',
      component: () => import('@/views/results/Results.vue'),
      meta: {
        pageTitle: 'Results',
        breadcrumb: [
          {
            text: 'Results',
            active: true,
          },
        ],
      },
    },     
    {
      path: '/patients',
      name: 'patients',
      component: () => import('@/views/patients/Patients.vue'),
      meta: {
        pageTitle: 'Patients',
        breadcrumb: [
          {
            text: 'Patients',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/patient',
      name: 'patient',
      component: () => import('@/views/patients/Patient.vue'),
      meta: {
        pageTitle: 'Patient',
        breadcrumb: [
          {
            text: 'Patient',
            active: true,
          },
        ],
      },
    },  
    {
      path: '/patient/:id',
      name: 'patient-id',
      component: () => import('@/views/patients/Patient.vue'),
      meta: {
        pageTitle: 'Patient',
        breadcrumb: [
          {
            text: 'Patient',
            active: true,
          },
        ],
      },
    },  
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },                 
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/Roles.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
      },
    },                 
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/2fa',
      name: '2fa',
      component: () => import('@/views/2FA.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activate/:id/:code',
      name: 'activate',
      component: () => import('@/views/Activate.vue'),
      meta: {
        noLoginRequired: true,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
